import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/layout';
import ContactCard from '../components/contactCard';

const Template = ({ data }) => {
	const title = data.mdx.frontmatter.title;
	/* const date = data.markdownRemark.frontmatter.date; */
	const body = data.mdx.body;

	return (
		<Layout>
			<Link className='text-gray-400 hover:text-gray-300' to="/">← Back</Link>
			<h1 className='text-3xl mt-8 mb-4'>{title}</h1>
			<div className='blogpost'>
				<MDXRenderer>{body}</MDXRenderer>
			</div>
			<ContactCard />
		</Layout>
	);
};

export const postQuery = graphql`
	query($pathSlug: String!) {
		mdx(frontmatter: { path: { eq: $pathSlug } }) {
		  body
		  frontmatter {
			title
			date(fromNow: true)
			path
			excerpt
			tags
		  }
		}
	  }
`;


export default Template;