import React, { useReducer } from 'react';

import LoadingSpinner from '../ui-components/LoadingSpinner';
import SuccessMessage from '../ui-components/SuccessMessage';
import ErrorMessage from '../ui-components/ErrorMessage';

const INITIAL_STATE = {
    body: '',
    email: '',
    twitter: '',
    status: 'IDLE'
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'updateFieldValue':
            return {...state, [action.field]: action.value};

        case 'updateStatus':
            return { ...state, status: action.status};

        default:
            return INITIAL_STATE;
    }
};

const ContactCard = () => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const setStatus = status => dispatch({ type: 'updateStatus', status });

    const updateFieldValue = field => event => {
        dispatch({
            type: 'updateFieldValue',
            field,
            value: event.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setStatus('PENDING');

        fetch('../api/sendgrid', {
            method: 'POST',
            body: JSON.stringify(state)
        })
            .then(response => response.text())
            .then(text => console.log(text))
            .then(() => {setStatus('SUCCESS')})
            .catch(error => {
                console.log(error);
                setStatus('ERROR');
            })
    };

    return (
        <div className='bg-gray-850 p-8 -mx-8 mt-16 rounded-xl'>
            <h4 className='text-xl font-semibold mb-4'>Thoughts?</h4>
            <p>If this resonated with you, feel free to drop me a line. Or in case something is incorrect or unclear, please let me know!</p>
            <form
                className='mt-4'
                onSubmit={handleSubmit}
            >
                <textarea
                    className='w-full bg-gray-900 px-4 py-2 rounded focus:outline-none focus:ring-1 focus:ring-blue-600'
                    placeholder='Hey, this sounds interesting.'
                    value={state.body}
                    onChange={updateFieldValue('body')}
                    required
                >                  
                </textarea>
                <div className='grid grid-cols-2 gap-3 mt-3'>
                    <input
                        className='block w-full bg-gray-900 px-4 py-2 rounded focus:outline-none focus:ring-1 focus:ring-blue-600'
                        placeholder="Email (optional)"
                        value={state.email}
                        onChange={updateFieldValue('email')}
                        type='email'>
                    </input>
                    <input
                        className='block w-full bg-gray-900 px-4 py-2 rounded focus:outline-none focus:ring-1 focus:ring-blue-600'
                        placeholder='Twitter handle (optional)'
                        value={state.twitter}
                        onChange={updateFieldValue('twitter')}
                        type='text'>
                    </input>
                </div>
                <div className={`flex justify-end mt-4`}>
                    {state.status === 'SUCCESS' &&
                        <SuccessMessage>Thanks for the message!</SuccessMessage>}
                    {state.status === 'ERROR' &&
                        <ErrorMessage>Something went wrong.</ErrorMessage>}
                    <button
                        className='flex items-center justify-center w-36 px-4 py-2 text-sm font-medium rounded-lg transition-colors bg-blue-800 shadow-btn-inner hover:bg-blue-600 focus:outline-none'
                        disabled={state.status === 'PENDING'}
                        type='submit'
                    >
                        {state.status === 'PENDING' ? <LoadingSpinner /> : 'Send feedback'}
                    </button>
                </div>
            </form>
        </div>
    )
};

export default ContactCard;